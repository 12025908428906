.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
.bg {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  background-attachment: scroll;
}
.bg::before {
  background-image: url("img/ab_background_1.jpeg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-attachment: scroll;
  content: '';
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: -1;
  opacity: 0.7;
}
.Container {
  width: 1000px;
  max-width: calc(100% - 20px);
  display: flex;
  align-items: center;
  height: fit-content;
  background-color: #FFFEFE;
  flex-direction: column;
  margin: 65px;
  border-style: solid;
  border-color: #EEEEEE;
  border-width: thin;
}
.Header {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.navLine {
  width: 60px;
  height: 0;
  border: 1px solid #EEEEEE;
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wideLine {
  width: 100%;
  height: 0;
  border: 1px solid #EEEEEE;
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homePicWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: small;
  font-family: 'Lato', sans-serif;
}
.FullSizeNavbar {
  list-style-type: none;
  display: flex;
  width: 70%;
  justify-content: space-evenly;
  text-decoration: none;
  padding-top: 50px;
  font-family: 'Lato', sans-serif;
}
.PageContent {
  height: 100%;
  margin: 65px;
  font-family: 'EB Garamond', serif;
  font-style: normal;
  font-size: large;
	font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  row-gap: 50px;
}
.TextContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  width: 75%;
  min-width: 300px;
  text-align: center;
  line-height: 1.5;
}
.mobileNavbar {
  display: flex;
  flex-direction: column;
  background-color: #FFFEFE;
  width: 100%;
  text-align: center;
  list-style: none;
  border-bottom: solid #EEEEEE thin;
  row-gap: 10px;
  padding: 10px;
  font-size: large;
  font-family: 'Lato', sans-serif;
}
.mobileNavbarButton {
  display: flex;
  flex-direction: column;
  background-color: #FFFEFE;
  width: 100%;
  text-align: center;
  list-style: none;
  border-style: solid;
  border-color: #EEEEEE;
  border-width: thin;
  row-gap: 10px;
  padding: 10px;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
}
.mobileNavbarWrapper {
  border-bottom: solid #EEEEEE thin;
  width: 100%;
  font-size: large;
  top: 0;
  position:-webkit-sticky;
  position: sticky;
}
input, select {
  width: 100%;
  padding: 12px;
  margin: 6px 0 4px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  font-family: sans-serif;
  font-size: 12px;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;
}
input:disabled {
  background: rgb(235,235,228);

}
button {
  align-items: center;
}
h2, h3 {
  font-family: 'Lato', sans-serif;
  color: #1c1c1c;
}
.normalPic {
  width: 70%;
  min-width: 300px;
}
.widePic {
  width: 100%;
  min-width: 300px;
}
.miniLogo {
  width: 10%;
  min-width: 50px;
}
.laPic {
  width: 70%;
  min-width: 350px;
}
.stylizedLogo {
  width: 20%;
  min-width: 150px;
  padding-top: 50px;
}
.zolaLogo {
  width: 20%;
  min-width: 150px;
}
.dividerImage {
  width: 30%;
  min-width: 150px;
}
.hotelLink {
  color: #CDA66F;
  text-decoration: underline;
}
.hotelLink:hover{
  color:black;
}
.buttonWrapper {
  text-align: center;
}
#hide {
  display: none;
}
button {
  font-family: 'Lato', sans-serif;
  color: #fff;
  background-color: #272727;
  border-color: #272727;
  border-width: 0;
  padding: 1em 2.5em;
}
button:hover {
  font-family: 'Lato', sans-serif;
  color: #fff;
  padding: 1em 2.5em;
  border-width: 0;
  background-color: rgba(28,28,28,.8);
  border-color: rgba(28,28,28,.8);
}