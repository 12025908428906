@media screen and (max-width:767px) {
  html {
    overflow: hidden;
    height: 100%;
  }
  
  body {
    overflow: auto;
    height: 100%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:link, :visited, :hover, :active { 
  color: #000000;
  text-decoration: none; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;  
}
